import SignUpPage from 'gatsby-theme-nurofen/src/templates/SignUpPage/SignUpPage'
import { graphql } from "gatsby"

export const query = graphql`
  query(
    $lang: String!
    $link: String!
    $pageBannerImageSize: Int = 2000
    $pageIdRegex: String
  ) {
    signUp(link: { eq: $link }, lang: { eq: $lang }) {
      link
      lang
      ...FragmentSeo
      signUpBanner {
        properties {
          ...FragmentBanner
        }
      }
      signUpHeroImageAltText
      signUpHeroImage {
        fallbackUrl
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 600, fit: CONTAIN) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mobileImage: gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 320, fit: CONTAIN) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      promoUrl
      promoId
      thankyouTitle
      thankyouDescription
      redirectUrl
      redirectTimeout
      submenu
      consent
      formType
    }
    pageLangs: allSignUp(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    ...FragmentCommonCompositions
  }
`

export default SignUpPage
